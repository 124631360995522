<template>
  <div class="type-class">
    <div class="type-public">
      <el-table :data="typelist" style="width: 100%" height="80%">
        <el-table-column prop="name" label="名称"> </el-table-column>
        <el-table-column label="操作">
          <template slot-scope="scope">
            <el-button
              size="mini"
              type="danger"
              @click="delrow(scope.$index, scope.row)"
              >删除</el-button
            >
          </template>
        </el-table-column>
      </el-table>
      <el-button type="primary" size="mini" @click="addtype()">增加</el-button>
    </div>

    <div class="type-public">
      <el-table :data="userlist" style="width: 100%" height="80%">
        <el-table-column prop="username" label="名称"> </el-table-column>
        <el-table-column prop="phone" label="联系方式"> </el-table-column>
        <el-table-column prop="number" label="工号"> </el-table-column>
        <el-table-column label="操作">
          <template slot-scope="scope">
            <el-button
              size="mini"
              type="primary"
              @click="setuser(scope.$index, scope.row)"
              >修改</el-button
            >
          </template>
        </el-table-column>
      </el-table>
      <el-button type="primary" size="mini" @click="adduser()">增加</el-button>
    </div>

    <el-dialog
      title="用户修改"
      :visible.sync="setuserdialog"
      width="80%"
      :before-close="
        () => {
          setuserdialog = false;
        }
      "
    >
      <el-radio
        v-for="(item, index) in t"
        v-model="userradio"
        :key="'r' + index"
        :label="item.type"
        >{{ item.name }}</el-radio
      >
      <span slot="footer" class="dialog-footer">
        <el-button size="mini" @click="setuserdialog = false">取 消</el-button>
        <el-button size="mini" type="primary" @click="setusersure"
          >确 定</el-button
        >
      </span>
    </el-dialog>
  </div>
</template>

<script>
export default {
  data() {
    return {
      userradio: "",
      setuserdialog: false,
      typelist: [],
      userlist: [],
      t: [],
      nus: "",
    };
  },
  mounted() {
    this.init();
  },
  methods: {
    init() {
      this.$axios.get("/appealapi/gettype.php").then((res) => {
        this.typelist = res.data;
      });
      this.$axios.get("/appealapi/getua.php").then((res) => {
        this.userlist = res.data.u;
        this.t = res.data.t;
      });
    },
    addtype() {
      this.$prompt("请输入类型名称", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
      })
        .then(({ value }) => {
          this.$axios.get("/appealapi/addtype.php?t=" + value).then((res) => {
            if (res.data == "success") {
              this.init();
            }
          });
        })
        .catch(() => {});
    },
    delrow(index, row) {
      this.$confirm("此操作将永久删除该信息, 是否继续?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          this.$axios.get("/appealapi/deltype.php?i=" + row.Id);
          this.$message.success("删除成功！");
          this.init();
        })
        .catch(() => {});
    },
    adduser() {
      this.$prompt("请输入用户openid", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
      })
        .then(({ value }) => {
          this.$axios.get("/appealapi/adduser.php?o=" + value);
          this.init();
        })
        .catch(() => {});
    },
    setuser(index, row) {
      this.userradio = "";
      this.nus = row.id;
      this.setuserdialog = true;
    },
    setusersure() {
      this.$axios.get(
        "/appealapi/setusertype.php?id=" + this.nus + "&t=" + this.userradio
      );
      this.init();
      this.setuserdialog = false;
    },
  },
};
</script>

<style lang="scss" scoped>
.type-class {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  flex-direction: column;
  .type-public {
    width: 100%;
    height: 50%;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    flex-direction: column;
  }
}
</style>